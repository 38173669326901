import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import { CivilData, selectors as controlSelectors } from '../..'
import { UpdateLastNamesPayload } from '../../../../services/api'
import { actions as lastNamesActions } from '../../../lastNames'
import { submitControls } from '../../../lastNames/sagas'

/**
 * Vérifie si le(s) noms renseignés match avec le(s) noms renseignés lors de la souscription (non sensible à la casse).
 * @param lastNames nom(s) renseigné(s) dans l' (les) input(s)
 * @param oldLastNames nom(s) recupéré(s) depuis le parcours de souscription
 * @return {boolean} true si le(s) noms renseignés match avec le(s) noms renseignés lors de la souscription
 */
function isLastNameMatches(lastNames: string[], oldLastNames: string[]) {
    if (lastNames.length !== oldLastNames.length) return false
    return lastNames.every(
        (lastName) =>
            oldLastNames
                .map((oldLastName) => {
                    return oldLastName.toUpperCase()
                })
                .indexOf(lastName.toUpperCase()) >= 0
    )
}

/**
 * Vérifie si l'un des éléments du nom de famille a été modifié.
 * @param payload contient les données provenant de la vue
 * @param civilData contient les données récupérées depuis le serveur, avant modification
 * @return {boolean} true si l'un des éléments a été modifié
 */
function lastNamesHasChanged(payload: UpdateLastNamesPayload, civilData: CivilData) {
    const inputtedlastNames = payload.lastNames
    const { lastNames } = civilData
    if (!inputtedlastNames || !lastNames) {
        return true
    }
    if (!isLastNameMatches(inputtedlastNames, lastNames)) {
        return true
    }
    if (payload.marriedLastName?.toUpperCase() !== civilData.marriedLastName?.toUpperCase()) {
        return true
    }

    return payload.preferredLastName?.toUpperCase() !== civilData.preferredLastName?.toUpperCase()
}

export function* control(): SagaIterator {
    const lastNames: string[] = yield select(controlSelectors.getLastNames)
    const marriedLastName: string = yield select(controlSelectors.getMarriedLastName)
    const preferredLastName: string = yield select(controlSelectors.getPreferredLastName)
    const payload: UpdateLastNamesPayload = { lastNames, marriedLastName, preferredLastName }
    const civilDataProperties: CivilData = yield select(controlSelectors.getCivilDataProperties)
    if (lastNamesHasChanged(payload, civilDataProperties)) {
        yield put(lastNamesActions.openCloseControlPopin())
    } else {
        yield call(submitControls, payload)
    }
}
