import React, { ChangeEvent, useCallback, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import styled from 'styled-components'

import { COUNTRY_CODE } from '@nickel/l10n/constants'
import Field from '@nickel/ui/components/legacy/form/Field'
import Input from '@nickel/ui/components/legacy/form/Input'

import Names from '../../components/Names'
import { actions as controlsActions, selectors as controlsSelectors } from '../../store/controls'
import { selectors as registrationFormsSelectors } from '../../store/registrationForms'
import { RootState } from '../../store/rootReducer'

import LastNameAlert from './modals'

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
    `
}

const MAX_FIELDS_COUNT = 2

const OtherNames = ({ dispatch }: { dispatch: Dispatch }) => {
    const { t } = useTranslation()
    const isOwner = useSelector(controlsSelectors.getIsOwner)

    const marriedLastName = useSelector<RootState, string | undefined>(controlsSelectors.getMarriedLastName)
    const preferredLastName = useSelector<RootState, string | undefined>(controlsSelectors.getPreferredLastName)
    useEffect(() => {
        dispatch(
            controlsActions.setCivilDataBeforeEdit({
                marriedLastName,
                preferredLastName
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const setMarriedLastName = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (isOwner) {
                dispatch(controlsActions.setMarriedLastNameOwner({ marriedLastNameOwner: e.target.value }))
            } else {
                dispatch(controlsActions.setMarriedLastNameGuardian({ marriedLastNameGuardian: e.target.value }))
            }
        },
        [dispatch, isOwner]
    )
    const setPreferredLastName = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (isOwner) {
                dispatch(controlsActions.setPreferredLastNameOwner({ preferredLastNameOwner: e.target.value }))
            } else {
                dispatch(controlsActions.setPreferredLastNameGuardian({ preferredLastNameGuardian: e.target.value }))
            }
        },
        [dispatch, isOwner]
    )

    return (
        <>
            <Field label={t('controls:LAST_NAMES.marriedLastName')}>
                <Input name="married-last-name" onChange={setMarriedLastName} value={marriedLastName} />
            </Field>
            <Field label={t('controls:LAST_NAMES.preferredLastName')}>
                <Input name="preferred-last-name" onChange={setPreferredLastName} value={preferredLastName} />
            </Field>
        </>
    )
}

const Form = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isOwner = useSelector(controlsSelectors.getIsOwner)

    const lastNames = useSelector<RootState, string[] | undefined>(controlsSelectors.getLastNames)
    const subscriptionCountry = useSelector<RootState, string | null>(registrationFormsSelectors.getSearchCountry)
    const preferredLastName = useSelector<RootState, string | undefined>(controlsSelectors.getPreferredLastName)
    useEffect(() => {
        dispatch(
            controlsActions.setCivilDataBeforeEdit({
                lastNames,
                preferredLastName
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createSetLastName = useCallback(
        (names: string[]) => {
            if (isOwner) {
                dispatch(controlsActions.setLastNamesOwner({ lastNamesOwner: names }))
            } else {
                dispatch(controlsActions.setLastNamesGuardian({ lastNamesGuardian: names }))
            }
        },
        [dispatch, isOwner]
    )

    return (
        <Styled.Container>
            <LastNameAlert />
            <Names
                addButtonDataTestId="AddLastName"
                name="lastName"
                maxFields={MAX_FIELDS_COUNT}
                onChange={createSetLastName}
                removeButtonDataTestId="RemoveLastName"
                title={t('controls:LAST_NAMES.lastNames')}
                value={lastNames ?? []}
            />
            {subscriptionCountry === COUNTRY_CODE.FR && <OtherNames dispatch={dispatch} />}
        </Styled.Container>
    )
}

export default Form
